.call-logo{
    position: fixed;
    bottom: 20px;
    z-index: 9;
    right: 20px;
    cursor: pointer;
  }
  .call-logo-tool-tip{
    position: fixed;
    bottom: 20px;
    z-index: 9;
    right: 100px;
    width: 186px;
    height: 72px;
    border-radius: 5px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);
    background-color: #ffffff;
  }

@media only screen and (max-width: 768px) {
    .call-logo{
        bottom: 40px;
        right: 10px;
        }
}