.pagination{
    display: flex;
    justify-content: center;
    padding-top: 100px;
    ul{
      list-style: none;
      padding: 0px;
      display: flex;
  
      li{
        background: #40079b;
        padding: 10px 15px;
        margin-right: 4px;
        border-radius: 4px;
        font-family: sans-serif;
        color: #fff;
        font-size: 1.1rem;
        min-width: 20px;
        text-align: center;
        user-select: none;
  
        &:last-child{
          margin-right: 0px;
        }
  
        &:not(.active):not(.disabled){
          cursor: pointer;
        }
        &:not(.active):not(.disabled):hover{
          background: #683eb6;
        }
        &:not(.active):not(.disabled):active{
          background: #3E207C;
        }
  
        &.active{
          background: #6f9dff;
        }
        &.disabled{
          background: #FBEAFF;
          pointer-events: none;
        }
      }
    }
  }